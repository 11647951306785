/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~normalize.css/normalize.css';
body {
  padding: 10px;
  background-color: #b6c9f0;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
